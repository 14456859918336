.reset-password {
  margin-top: 50px;
}
.reset-password h2 {
  margin-bottom: 40px;
  font-weight: bolder;
}
.icheckbox_square.checked {
  margin-right: 8px;
}
.login-redirect {
  margin-top: 40px;
}
.login-redirect a {
  color: red;
  text-decoration: none;
}
.text-center {
  text-align: center;
}
.italic {
  font-style: italic;
}
.bold {
  font-weight: bolder;
}
body {
  color: #454545;
  font-family: Arial, sans-serif;
  font-size: 14px;
  position: relative;
  margin: 0px;
  padding: 0px;
  background: #ffffff;
}
.main-color-text {
  color: #e1000f ! important;
}
ul.red-carret {
  margin: 0.75em 0;
  list-style: none;
}
ul.red-carret li:before {
  content: "";
  border-color: transparent #e1000f;
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.45em;
  display: block;
  height: 0;
  width: 0;
  left: -1em;
  top: 0.9em;
  position: relative;
}
ul.red-bullet {
  margin: 0;
  padding: 0 ;
  list-style: none;
}
ul.red-bullet li {
  padding-left: 1em;
  text-indent: -0.7em;
}
ul.red-bullet li:before {
  content: "• ";
  color: #e1000f;
}
ul.red-links {
  margin: 0.75em 0;
  list-style: none;
}
ul.red-links li:before {
  content: "";
  border-color: #e1000f;
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.45em;
  display: block;
  height: 0;
  width: 0;
  left: -1em;
  top: 0.9em;
  position: relative;
}
ul.red-links a {
  color: #e1000f;
  font-weight: bolder;
}
#faq {
  font-size: 1em;
}
#faq .faqTitle {
  font-size: 2.5em;
  text-align: center;
  color: #e1000f;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: bolder;
}
#faq .FaqHeader {
  font-size: 1.5em;
  text-align: left;
  color: #e1000f;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: bold;
}
#faq h4.panel-title {
  font-size: 1.1em;
}
#faq .panel-collapse {
  padding-top: 20px;
  padding_bottom: 20px ! important;
  padding-left: 30px;
  padding-right: 30px;
}
#cnil .cnilTitle {
  font-size: 2.5em;
  text-align: center;
  color: #e1000f;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: bolder;
  line-height: 1.5em;
}
#cnil .cnilText {
  font-size: 1em;
  padding-left: 40px;
  padding-right: 40px;
}
.img-separator {
  width: 100%;
  height: auto;
}
.navbar {
  margin-bottom: 0px ! important;
}
.img-separator {
  width: 100%;
  height: auto;
}
.links {
  padding-left: 0px;
}
.red-welcome,
.black-welcome {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-family: Arial;
  font-weight: bolder;
  font-size: 1em;
  line-height: 1em;
}
.no-margin {
  margin-right: 0px ! important;
  margin-left: 0px ! important;
}
.red {
  color: #e1000f;
}
.bold {
  font-weight: bolder;
}
.red-welcome {
  margin-top: 15px;
  color: #e1000f;
}
.black-welcome {
  color: black;
  margin-bottom: 15px;
}
@media (min-width: 992px) {
  .red-welcome,
  .black-welcome {
    font-size: 1.5em;
    line-height: 1.5em;
  }
}
@media (min-width: 1200px) {
  .pull-right-lg {
    float: right;
  }
}
.logo-group {
  float: right;
}
.logo-group img {
  width: auto;
}
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}
/* Animation */
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.block-image {
  margin-bottom: 20px;
}
.embossed-heavy {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 2px 3px rgba(255, 255, 255, 0.3), inset 0 -2px 3px rgba(0, 0, 0, 0.3), 0 1px 1px rgba(255, 255, 255, 0.9);
}
body.default {
  background-image: url('/build/bundles/parissportifs/image/background-marque.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-color: #D3D8DE;
}
.usable {
  background-color: white;
  margin-top: 55px;
}
body {
  padding-top: 0px;
  padding-bottom: 0px;
}
body.fixed .content-header .navbar.stuck {
  top: 0px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #004179 ! important;
  color: white;
}
.colored-line {
  background-color: #e1000f;
}
.shadow-centered {
  box-shadow: 8px 0px 12px #aaa, -8px 0px 12px #aaa;
  width: 100%;
}
.base {
  padding-left: 0px ;
  padding-right: 0px;
}
.middle {
  height: auto;
  min-height: 800px;
}
.middle .shadow-centered {
  height: auto;
  min-height: 800px;
}
.paddingtop15 {
  padding-top: 15px;
}
.paddingbottom20 {
  padding-bottom: 20px;
}
.floatnone {
  float: none! important;
  margin-left: auto;
  margin-right: auto;
}
.btn-danger,
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  background: #e1000f ! important;
  color: #ffffff ! important;
}
#cnil .cnilTitle {
  font-size: 2.5em;
  text-align: center;
  color: #e1000f;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: bolder;
  line-height: 1.5em;
}
#cnil .upperTrBackgroundRed {
  background: #e1000f;
  color: white;
}
#cnil .section-title-inner {
  line-height: 1.1em;
}
#program-access {
  font-weight: bolder;
  color: black;
  font-size: 1.2em;
  margin-bottom: 20px;
}
.icheckbox_square {
  margin-right: 10px;
}
img.admin-preview {
  max-height: 200px;
  max-width: 200px;
}
ul.red-carret {
  margin: 0.75em 0;
  list-style: none;
}
ul.red-carret li:before {
  content: "";
  border-color: transparent rgba(255, 13, 0, 0.95);
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.45em;
  display: block;
  height: 0;
  width: 0;
  left: -1em;
  top: 0.9em;
  position: relative;
}
.padding-0 {
  padding-right: 0;
  padding-left: 0;
}
.img-50 {
  max-height: 50px;
}
#test {
  color: yellow;
}
#myaccount .account-title {
  color: #e1000f;
  font-size: 2em;
  text-align: center;
  margin-bottom: 50px;
  font-weight: bolder;
  line-height: 1.5em;
}
#myaccount .link-action {
  margin-right: 5px;
}
#footer {
  border-bottom-color: #e1000f;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-top-color: #e1000f;
  border-top-width: 2px;
  border-top-style: solid;
  margin-top: 40px;
  padding-left: 0px;
  background-color: white;
  min-height: 80px;
  height: auto;
  color: white;
}
#footer .footer-link {
  color: black ! important;
}
#footer .logo-group {
  margin-top: 10px;
}
#footer .copyright {
  float: right;
  font-size: 1em;
}
.left-footer {
  padding-top: 30px;
}
.footer-link {
  color: black ! important;
  margin-left: 10px;
  font-size: 0.8em;
}
#user-menu {
  background-color: #D3D8DE;
}
#user-menu a {
  color: #e1000f;
  text-decoration: none;
}
#user-menu input {
  background-color: white;
  color: black;
}
.full-color {
  margin-left: 0px ! important;
  margin-right: 0px ! important;
  padding-left: 0px ! important;
  padding-right: 0px ! important;
}
.header-bg {
  background-image: none;
  -webkit-background-size: cover;
  /* pour anciens Chrome et Safari */
  background-size: cover;
  /* version standardisée */
  background-repeat: no-repeat;
}
#header {
  height: auto;
}
#header .btn-black-rect {
  background-color: #000000;
  color: #e1000f;
  width: 100%;
  border: 1px solid black;
}
#header .full-width {
  width: 100%;
  display: inline-block;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 0px;
  margin-top: 0px;
}
#header .img-link {
  left: -18px;
  position: absolute;
}
#header .club {
  color: #e6e7e7;
  font-size: 1.5em;
  font-weight: 900;
}
#header .pro {
  color: #e1000f;
  font-size: 1.5em;
  font-weight: 900;
}
#header .slogan {
  color: #e6e7e7;
  font-size: 1.2em;
  font-weight: 900;
}
#header .slogan-wrapper {
  margin-top: 10px;
  line-height: 1.5em;
}
#header .connection-menu {
  z-index: 10000;
  position: absolute;
  top: 0px;
  right: 0px;
}
#header .connection-menu .navbar-nav > li > a {
  font-size: 0.80em;
  padding: 10px 15px;
}
#header .connection-menu #program-access {
  color: #e1000f;
  margin-bottom: 25px;
  font-size: 1.2em;
}
#header .connection-menu .nav > li.active,
#header .connection-menu .nav > li > a:hover,
#header .connection-menu .nav > li > a:focus,
#header .connection-menu .nav > li:hover > a {
  background-image: url('/bundles/smarteritstanley/image/blueblock.png');
  background-color: white;
  height: 40px;
}
#header .connection-menu .nav > li > a:hover,
#header .connection-menu .nav > li > a:focus {
  text-decoration: none;
  background-image: url('/bundles/smarteritstanley/image/blueblock.png');
  background-color: white;
  height: 40px;
}
#header .connection-menu .nav .open > a,
#header .connection-menu .nav .open > a:hover,
#header .connection-menu .nav .open > a:focus,
#header .connection-menu .cartMenu:hover > a.dropdown-toggle {
  background-image: url('/bundles/smarteritstanley/image/blueblock.png');
  background-color: white;
  height: 40px;
}
.blue-block {
  background-image: url('/bundles/smarteritstanley/image/blueblock.png');
  height: 40px ! important ;
  width: 249px;
}
.blue-block .dropdown-toggle {
  height: 40px ! important ;
}
.button-connect {
  position: absolute;
}
.connect {
  font-size: 8px;
  font-family: "Open Sans";
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 2;
  text-align: center;
  -moz-transform: matrix(3.02380952, 0, 0, 3, 0, 0);
  -webkit-transform: matrix(3.02380952, 0, 0, 3, 0, 0);
  -ms-transform: matrix(3.02380952, 0, 0, 3, 0, 0);
}
#login-dp {
  padding: 5px 5px 0;
  overflow: hidden;
  background-color: #cccccc;
  border-color: #e1000f;
  border-width: 3px;
  min-width: 234px;
  width: 234px;
}
#login-dp .btn-disconnect {
  margin-top: 30px;
}
#login-dp .help-block {
  font-size: 12px;
}
#login-dp .bottom {
  background-color: rgba(211, 211, 211, 0.8);
  border-top: 1px solid #ddd;
  clear: both;
  padding: 14px;
}
#login-dp .social-buttons {
  margin: 12px 0;
}
#login-dp .social-buttons a {
  width: 49%;
}
#login-dp .form-group {
  margin-bottom: 10px;
}
.on-screen > a {
  color: #e1000f ! important;
}
.margin-top-20 {
  margin-top: 20px ! important;
}
@media (max-width: 800px) {
  .userMenu > li > a {
    font-size: 1em;
  }
}
@media (min-width: 801px) {
  .userMenu > li > a {
    font-size: 1.5em;
  }
}
.cartMenu a.dropdown-toggle,
.cartMenu a#cart-sidebar-toggle {
  font-size: 1.5em;
}
.icon-search {
  font-size: 1.5em ! important;
}
.middle {
  margin-top: -6px;
}
.middle .shadow-centered {
  display: inline-block;
  margin-left: 3px;
  margin-right: 3px;
  padding-bottom: 20px;
}
.img-welcome {
  box-shadow: 0px 8px 12px #aaa;
}
.top-label {
  color: black;
}
@media (max-width: 800px) {
  .xs-text-centered {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}
.homepage-block {
  margin-top: 25px;
}
body {
  padding-top: 60px;
  padding-bottom: 40px;
  background-color: #eee;
}
.main-color-text {
  color: #e1000f ! important;
}
.block-title-2 {
  line-height: 1.5em;
}
.margin-right-15 {
  margin-right: 15px;
}
.registrationSuccess {
  background-color: #333333;
  border-color: #e1000f;
  border-width: 10px;
  border-style: solid;
  color: #e1000f;
}
.btn-stanley {
  background-color: #e1000f;
  color: #333333;
  border-color: #333333;
}
label.required:after {
  color: #e32;
  content: ' *';
  display: inline;
}
@media (max-width: 480px) {
  .modal {
    width: 320px;
  }
}
#why-modal .logo_stanley {
  margin-top: 10px;
  float: left;
  height: 90%;
  margin-bottom: 10px;
}
#why-modal .logo_facom {
  margin-top: 15px;
  float: left;
  height: 90%;
  margin-bottom: 10px;
}
#why-modal .logo_expert {
  margin-top: 12px;
  float: left;
  height: 90%;
  margin-bottom: 10px;
}
#why-modal .logo_dewalt {
  margin-top: 10px;
  float: left;
  height: 90%;
  margin-bottom: 10px;
}
#why-modal .modal-body {
  padding: 0px;
}
#why-modal .body-content {
  padding: 15px;
}
#why-modal .modal-header {
  border-bottom: 1px solid #e1000f;
}
#why-modal .modal-footer {
  border-top: 1px solid #e1000f;
}
#why-modal .modal-dialog {
  background-color: #333333;
  border-color: #e1000f;
  border-width: 10px;
  border-style: solid;
  color: #e1000f;
}
#why-modal .modal-content {
  background-color: #333333;
}
#why-modal .logos {
  margin-top: 40px;
}
#register-modal .lbl-num-client {
  margin-top: 20px;
  margin-bottom: 40px;
}
#register-modal .modal-body {
  padding: 0px;
}
#register-modal .body-content {
  padding: 30px;
}
#register-modal .logos {
  padding-top: 10px;
  margin-top: 40px;
}
#register-modal .header-content {
  min-height: 30px;
}
#register-modal legend.stanley {
  width: inherit;
  /* Or auto */
  padding: 0 10px;
  /* To give a bit of padding on the left and right */
  border-bottom: none;
  color: #e1000f;
  border-color: #e1000f;
}
#register-modal fieldset.stanley {
  border: 1px groove #e1000f !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #e1000f;
  box-shadow: 0px 0px 0px 0px #e1000f;
}
#register-modal legend.stanley {
  font-size: 1.2em !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
  color: #e1000f;
  border-color: #e1000f;
}
#register-modal .input-stanley {
  border: 1px solid #e1000f;
  /*stroke*/
  background: #ffffff;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  /*Will not allow bg color to leak outside borders*/
  -webkit-box-shadow: inset 1.677579px 2.487113px 10px 0 rgba(0, 0, 0, 0.35), 0 0 32px 0 #1d1d1b;
  /*inner shadow, drop shadow*/
  -moz-box-shadow: inset 1.677579px 2.487113px 10px 0 rgba(0, 0, 0, 0.35), 0 0 32px 0 #1d1d1b;
  /*inner shadow, drop shadow*/
  box-shadow: inset 1.677579px 2.487113px 10px 0 rgba(0, 0, 0, 0.35), 0 0 32px 0 #1d1d1b;
  /*inner shadow, drop shadow*/
  border-radius: 0px;
}
#register-modal .registration-title {
  color: #e1000f;
  text-align: center;
  font-size: 2em;
}
#register-modal .modal-header {
  border-bottom: 1px solid #e1000f;
}
#register-modal .modal-footer {
  border-top: 1px solid #e1000f;
}
@media (max-width: 767px) {
  #register-modal .logo_stanley_black-decker {
    width: 85%;
  }
}
@media (min-width: 768px) {
  #register-modal .modal-dialog {
    width: 85%;
  }
}
@media (min-width: 992px) {
  #register-modal .modal-dialog {
    width: 70%;
  }
}
@media (min-width: 1200px) {
  #register-modal .modal-dialog {
    width: 70%;
  }
}
#register-modal .modal-dialog {
  background-color: #333333;
  border-color: #e1000f;
  border-width: 10px;
  border-style: solid;
  color: #e1000f;
  height: auto;
  padding: 0;
}
#register-modal .modal-content {
  background-color: #333333;
  height: auto;
}
.logos {
  position: relative;
  background: #ffffff;
  width: 100%;
  min-height: 50px;
  margin-top: 15px;
}
#form-signin {
  max-width: 670px;
  padding: 15px;
  margin: 0 auto;
}
#form-signin .identify {
  margin-top: 20px;
}
#form-signin .access-label {
  margin-bottom: 20px;
}
#form-signin .forget-submit {
  margin-top: 20px;
}
#form-signin .forget {
  margin-top: 20px;
}
#form-signin .form-container {
  box-shadow: -5px 5px 10px 11px rgba(0, 0, 0, 0.2);
  background-color: #333333;
  border-color: #e1000f;
  border-width: 10px;
  border-style: solid;
  color: #e1000f;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0;
  text-decoration: none;
}
@media (max-width: 767px) {
  #form-signin .form-container .logo_stanley_black-decker {
    width: 100%;
  }
}
@media (min-width: 768px) {
  #form-signin .form-container .logo_stanley_black-decker {
    width: 100%;
  }
}
@media (min-width: 992px) {
  #form-signin .form-container .logo_stanley_black-decker {
    width: 140%;
  }
}
@media (min-width: 1200px) {
  #form-signin .form-container .logo_stanley_black-decker {
    width: 140%;
  }
}
#form-signin .form-container .logo_stanley_black-decker {
  position: relative;
  top: -15px;
  left: -45px;
}
#form-signin .form-container .text {
  margin-top: 5px;
  color: #ffffff;
  font-size: 2.75em;
  /* 44px / 16px = 2.75em */
  font-weight: 800;
  text-transform: uppercase;
}
#form-signin .form-container .text-2 {
  margin-top: 40px;
  color: #ffffff;
  font-size: 2.375em;
  /* 38px / 16px = 2.375em */
  font-weight: 600;
  text-transform: uppercase;
}
#form-signin .form-container .text-3 {
  font-size: 1em;
  /* 16px / 16px = 1em */
  font-weight: bold;
  text-transform: uppercase;
}
#form-signin .form-container .colorfed406 {
  color: #e1000f;
}
#form-signin .form-container .text-4 {
  margin: 11px 0 0;
  color: #ffffff;
  font-size: 1.125em;
  /* 18px / 16px = 1.125em */
}
#form-signin .form-container .label-stanley {
  color: #ffffff;
  font-size: 1.125em;
  /* 18px / 16px = 1.125em */
}
#form-signin .form-container .bt_valider {
  float: right;
  border: none;
  background: transparent;
}
#form-signin .form-container .text-6 a {
  margin: 16px 0 0;
  font-size: 1.125em;
  /* 18px / 16px = 1.125em */
  text-decoration: underline;
  color: #e1000f;
}
#form-signin .form-container .forme-3 {
  margin: 33px auto 0;
  width: 591px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  /*stroke*/
  background: rgba(255, 255, 255, 0.3);
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  /*Will not allow bg color to leak outside borders*/
}
#form-signin .form-container .logo_stanley {
  margin-top: 10px;
  float: left;
  height: 90%;
  margin-bottom: 10px;
}
#form-signin .form-container .logo_facom {
  margin-top: 18px;
  float: left;
  height: 90%;
  margin-bottom: 10px;
}
#form-signin .form-container .logo_expert {
  margin-top: 12px;
  float: left;
  height: 90%;
  margin-bottom: 10px;
}
#form-signin .form-container .logo_dewalt {
  margin-top: 10px;
  float: left;
  height: 90%;
  margin-bottom: 10px;
}
.form-signin .form-signin-heading,
.form-signin .checkbox {
  margin-bottom: 10px;
}
.form-signin .checkbox {
  font-weight: normal;
}
.form-signin .form-control {
  position: relative;
  height: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
#program {
  font-family: "Open Sans";
}
#program .program-title {
  font-size: 0.9em;
  color: #e1000f;
  line-height: 1.2em;
}
#program .program-resume {
  margin-top: 0px;
  font-size: 1.2em;
  color: black;
  line-height: 1.2em;
}
#program .program-simple {
  margin-top: 15px;
  margin-bottom: 30px;
  font-size: 2em;
  color: #e1000f;
  font-weight: normal;
}
#program .program-header {
  display: block;
  position: relative;
  min-height: 50px;
  background-color: #cccccc;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  padding-bottom: 20px;
  height: 80px;
}
#program .program-header:before {
  content: "";
  position: absolute;
  right: 0px;
  border-color: #fff #e1000f #cccccc #fff;
  border-style: solid;
  border-width: 0px 60px 20px 0px;
  height: 0px;
  width: 0px;
  display: block;
}
#program .program-conclusion {
  font-size: 1.2em;
  text-align: center;
}
@media (max-width: 800px) {
  #program .xs-text-centered {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}
#promotion {
  margin-top: 40px;
}
#promotion .promotion-heading {
  background-color: black;
  color: white;
  background-image: none;
  text-align: center;
  border: none;
}
#promotion .promotion-division {
  background-color: #e1000f;
  color: black;
  background-image: none;
  text-align: center;
  border: none;
}
#promotion .promotion-title-heading {
  color: white;
  text-align: center;
  font-weight: bolder;
}
#promotion .promotion-title-division {
  color: black;
  text-align: center;
  font-weight: bolder;
}
#promotion .panel-group .panel + .panel {
  margin-top: 0px;
}
#promotion .promotion-reputation {
  font-weight: bolder;
  font-size: 1.7em;
}
#promotion .yellow-stanley {
  font-weight: bolder;
  font-size: 1.7em;
  background-color: #e1000f;
  color: black;
}
#promotion .encours {
  font-weight: bold;
  margin-left: 5px;
  margin-top: 30px;
}
#promotion .panel-content {
  background-color: #cccccc;
}
#promotion .promotion-title {
  font-size: 1.5em;
  font-weight: 900;
  margin-top: 40px;
}
#promotion .promotion-link {
  margin-left: 10px;
  margin-top: 10px;
}
#promotion thead > tr {
  background-color: #e1000f;
}
#promotion .table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #cccccc;
}
#promotion .promotion-interval {
  color: #a50010;
  font-size: 1.2em;
}
#promotion .promotion-image {
  margin-left: auto;
  margin-right: auto;
}
#promotion .proud-stanley {
  margin-bottom: 40px;
}
#promotion .proud-stanley img {
  margin-bottom: 10px;
}
#promotion .proud-facom {
  margin-bottom: 40px;
}
#promotion .proud-facom img {
  margin-bottom: 10px;
}
#promotion .proud-dewalt {
  margin-bottom: 40px;
}
#promotion .proud-dewalt img {
  margin-bottom: 10px;
}
#promotion .proud-expert {
  margin-bottom: 40px;
}
#promotion .proud-expert img {
  margin-bottom: 10px;
}
#promotion .promo-speech {
  font-size: 1.2em;
}
#promotion .download-label {
  font-weight: bolder;
}
#promotion .promo-footer {
  margin-top: 30px;
}
#promotion .underline {
  text-decoration: underline;
}
#shop-container #header .navbar-tshop {
  margin-left: 0px;
  margin-right: 0px;
}
#shop-container .middle {
  margin-top: -5px;
}
#shop-container .userMenu > li > a {
  color: black;
}
#shop-container .connection-menu #shop-container .navbar-nav > li > a {
  font-size: 0.80em;
  padding: 0px 0px;
  height: 40px;
}
#shop-container #shop-menu {
  margin-top: 0px;
}
#shop-container #shop-menu .dropdown-header > a {
  color: #e1000f;
}
#shop-container #shop-menu .sub-menu {
  color: black ! important;
}
#shop-container #shop-menu .brand-line .brand-button {
  color: #e1000f;
}
#shop-container #shop-menu .search-close {
  background: #e1000f ! important;
}
#shop-container .navbar-top {
  background: #e1000f;
  color: black;
}
#shop-container #shop h2 {
  color: #e1000f;
}
#shop-container #shop .brandLogoTitle {
  background-color: #e1000f;
}
#shop-container #shop .price {
  font-size: 1em;
}
#shop-container #shop #total-price {
  font-size: 1em;
}
#shop-container #delivery-container {
  margin-top: 30px;
}
#shop-container .costDetails {
  padding-left: 0px;
}
.presentation .reward-intro {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 32px;
  color: #e1000f;
  font-weight: bolder;
}
.presentation .inscription-success {
  margin-bottom: 40px;
}
.presentation .introduction {
  margin-bottom: 40px;
}
.presentation .img-responsive {
  margin: 0 auto;
}
.presentation .conclusion {
  margin-top: 30px;
  font-size: 32px;
  text-align: center;
  font-weight: bolder;
}
.presentation .concours {
  font-weight: bolder;
}
.presentation .concours h2 {
  font-size: 32px;
}
.presentation .big {
  font-size: 26px;
}
.presentation a {
  color: #e1000f;
  text-transform: none;
}
.animation h2 {
  color: #e1000f;
  text-align: center;
  font-weight: bolder;
  margin-top: 30px;
  margin-bottom: 40px;
  font-size: 32px;
}
.animation .explanation {
  font-weight: bolder;
  font-size: 28px;
  margin-bottom: 30px;
}
.animation .score {
  font-weight: bold;
  text-align: center;
}
.animation .classement {
  font-weight: bolder;
  font-size: 28px;
}
.animation .name {
  font-weight: bold;
}
.animation .rank-label {
  text-align: center;
  width: 100%;
  font-weight: bolder;
  font-size: 28px;
}
.animation .condition {
  text-align: center;
  width: 100%;
  font-weight: bolder;
  font-size: 14px;
  margin-bottom: 20px;
}
.hidden {
  display: none;
}
.message {
  margin-top: 40px;
  color: white;
  text-align: center;
  font-size: 1.2em;
  font-weight: bolder;
  line-height: 1.2em;
}
.quark-container .name {
  margin-top: 10px;
  color: #32932e;
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 0.7em;
  text-align: center;
}
.quark-container .classement {
  color: white;
  font-size: 0.7em;
  font-weight: bold;
  text-align: center;
}
.quark-container .date {
  color: darkorange;
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 0.7em;
  text-align: center;
}
.quark-container #rank {
  font-family: segment;
  text-align: center;
  font-size: 1.7em;
  color: red;
}
@font-face {
  font-family: segment;
  src: url('../font/DSEG7Classic-Bold.woff') format('woff');
}
@font-face {
  font-family: '14-LED';
  src: url('../font/14-LED.ttf.woff') format('woff'), url('../font/14-LED.ttf.svg#14-LED') format('svg'), url('../font/14-LED.ttf.eot'), url('../font/14-LED.ttf.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
#wrapper {
  position: relative;
  width: 210px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 90px;
  top: 90px;
}
.ranking {
  width: 400px;
  height: 237px;
  margin-left: auto;
  margin-right: auto;
  background-image: url(../image/animation/ranking.png);
  background-size: 400px 237px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 0%;
}
#theMarquee {
  height: 55px;
  background: linear-gradient(0deg, #111, #222);
  box-shadow: 0px 0px 2px 0px #aaa inset, 0px -1px 2px 0px #aaa inset, 2px -5px 5px 0px #111 inset, 0px -5px 5px 0px #111 inset, 2px 5px 5px 0px #111;
  border-radius: 5px;
}
#theInput {
  clear: both;
  margin: 25px 100px;
  width: 200px;
  text-align: center;
}
.light {
  width: 5px;
  height: 5px;
  margin: 1px 1px;
  text-align: center;
  font-size: 15px;
  float: left;
  border-radius: 50%;
}
.off {
  background-color: #121212;
}
.on {
  background-color: #a70e03;
  box-shadow: 0px 0px 5px #a70e03;
}
.postfix {
  color: red;
  float: left;
  margin-left: 0px;
  padding-left: 0px;
}
.ranking-container {
  min-height: 800px;
  padding-left: 0px;
  padding-right: 0px;
  background-image: url('/build/bundles/parissportifs/image/animation/ranking.png') ! important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 0%;
}
.ranking-font {
  font-size: 25px;
}
body.background-ranking {
  background-image: url('/build/bundles/parissportifs/image/background-ranking.png') ! important;
  background-size: cover;
  background-repeat: no-repeat;
}
.scoreboard {
  background-image: url('/build/bundles/parissportifs/image/animation/scoreboard.svg') ! important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0%;
}
.odometer-container {
  font-size: 3em;
}
.quark-container {
  background-color: #241e1e;
  border: 3px solid #999;
  font-size: 3vh;
  margin-top: 15%;
}
.detail-container {
  /* Table Layout */
  /* Mobile Layout */
}
.detail-container table.results {
  margin: 20px 0 0 0;
  border-collapse: collapse;
  border-spacing: 0;
  background: #212121;
  color: #fff;
}
.detail-container table.results th,
.detail-container table.results td {
  text-align: center;
}
.detail-container table.results thead {
  line-height: 12px;
  background: #e1000f;
  text-transform: uppercase;
}
.detail-container table.results thead th {
  color: #fff;
  padding: 10px;
  letter-spacing: 1px;
  vertical-align: bottom;
}
.detail-container table.results thead th:nth-child(1) {
  width: 15%;
  text-align: left;
  padding-left: 20px;
}
.detail-container table.results thead th:nth-child(2) {
  width: 15%;
}
.detail-container table.results thead th:nth-child(3) {
  width: 10%;
}
.detail-container table.results thead th:nth-child(4) {
  width: 15%;
}
.detail-container table.results thead th:nth-child(5) {
  width: 15%;
}
.detail-container table.results thead th:nth-child(6) {
  width: 15%;
}
.detail-container table.results tbody {
  font-size: 1em;
  line-height: 15px;
}
.detail-container table.results tbody tr {
  border-top: 2px solid rgba(225, 0, 15, 0.8);
  transition: background 0.6s, color 0.6s;
}
.detail-container table.results tbody tr:nth-child(even) {
  background: rgba(255, 255, 255, 0.2);
}
.detail-container table.results tbody tr:hover {
  color: #000;
  background: rgba(255, 255, 255, 0.7);
  font-weight: 900;
}
.detail-container table.results tbody td {
  padding: 12px;
}
.detail-container table.results tbody tr:hover td:first-child {
  background: rgba(0, 0, 0, 0);
}
.detail-container table.results tbody td:first-child {
  text-align: left;
  padding-left: 20px;
  font-weight: 700;
  background: rgba(225, 0, 15, 0.35);
  transition: backgrounf 0.6s;
}
.detail-container table.results tfoot {
  font-size: 0.8em;
}
.detail-container table.results tfoot tr {
  border-top: 2px solid #e1000f;
}
.detail-container table.results tfoot td {
  color: rgba(255, 255, 215, 0.6);
  text-align: left;
  line-height: 15px;
  padding: 15px 20px;
}
@media screen and (max-width: 400px) {
  .detail-container .hidden-xs {
    display: none;
  }
  .detail-container h1 {
    font-size: 34px;
    line-height: 36px;
    padding-left: 15px;
  }
  .detail-container article {
    margin: 10px 15px;
  }
  .detail-container table.results {
    font-size: 0.8em;
  }
}
.detail-container table {
  border-collapse: separate;
  border: solid black 1px;
  border-radius: 6px;
  -moz-border-radius: 6px;
}
.detail-container td,
.detail-container th {
  border-left: solid black 1px;
  border-top: solid black 1px;
}
.detail-container th {
  border-top: none;
}
.detail-container td:first-child,
.detail-container th:first-child {
  border-left: none;
}
.detail-container .user-score {
  margin-bottom: 30px;
  color: #e1000f;
  font-weight: bolder;
  font-size: 1.5em;
  text-align: center;
}
/*# sourceMappingURL=parissportifs.css.map */